import { useIntervalEffect } from "@react-hookz/web";
import YouTube from "@vip30/react-youtube";
import { useAtom } from "jotai";
import { useCallback, useState } from "react";
import type { YouTubePlayer } from "youtube-player/dist/types";

import {
  mirrorVideoAdd,
  mirrorVideoLoad,
  sendMirrorCustomEvent,
} from "shared/components/tracking/mirror";

import { onlyOnePlayingPlayerAtom } from "scmp-app/components/article/article-list/hooks";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { VideoPlayerYouTubeEventCustomizedParameters } from "scmp-app/components/tracking/google-analytics-4/types";

import type { TrackingData } from "./types";

/**
 * For supporting YouTube player control
 */
export const useYouTubeControl = (uniqueVideoId: string, trackingData: TrackingData) => {
  const [player, setPlayer] = useState<OrUndefined<YouTubePlayer>>();
  const [{ currentYoutubeVideoId }, setOnlyOnePlayingPlayer] = useAtom(onlyOnePlayingPlayerAtom);

  const [hasTriggeredThreeSecond, setHasTriggeredThreeSecond] = useState(false);

  const onePlusVideoVertical = trackingData.sectionString;

  const handleYouTubeVideoGA4Tracking = (
    customParameters: VideoPlayerYouTubeEventCustomizedParameters,
  ) => {
    if (customParameters.article_id === undefined) delete customParameters.article_id;

    sendGA4Tracking({
      action: "sys",
      category: "video",
      customized_parameters: customParameters,
      subcategory: "play",
    });
  };

  useIntervalEffect(
    async () => {
      const currentTime = await player?.getCurrentTime();
      const totalTime = await player?.getDuration();

      if (totalTime && currentTime && currentTime >= 3) {
        handleYouTubeVideoGA4Tracking({
          action_type: "play" as const,
          article_id: trackingData.ga4ArticleEntityId,
          player_name: "youtube_pfp" as const,
          position: trackingData.ga4Position ?? "article_body",
          time: 3,
          video_id: trackingData.videoEntityId,
          youtube_id: trackingData.youTubeVideoId,
        });

        setHasTriggeredThreeSecond(true);
      }
    },
    currentYoutubeVideoId && !hasTriggeredThreeSecond ? 500 : undefined,
  );

  const [lastSeenPercentage, setLastSeenPercentage] = useState(0);

  useIntervalEffect(
    async () => {
      const currentTime = await player?.getCurrentTime();
      const durationOfVideo = await player?.getDuration();

      if (!currentTime || !durationOfVideo) return;

      const percentageElapsed = Math.round((currentTime / durationOfVideo) * 100);

      if (
        percentageElapsed > 0 &&
        percentageElapsed % 10 === 0 &&
        percentageElapsed !== lastSeenPercentage
      ) {
        handleYouTubeVideoGA4Tracking({
          action_type: "play",
          article_id: trackingData.ga4ArticleEntityId,
          complete_pct: percentageElapsed,
          player_name: "youtube_pfp",
          position: trackingData.ga4Position ?? "article_body",
          time: Math.round(currentTime),
          video_id: trackingData.videoEntityId,
          youtube_id: trackingData.youTubeVideoId,
        });

        sendMirrorCustomEvent({
          action: `Video Progress ${percentageElapsed}%`,
          event_name: "video_plays",
          video_vertical: onePlusVideoVertical,
        });

        setLastSeenPercentage(percentageElapsed);
      }
    },
    currentYoutubeVideoId && lastSeenPercentage !== 100 ? 250 : undefined,
  );

  const handleOnEnd = useCallback(() => {
    setPlayer(undefined);
  }, []);

  const handleOnReady = useCallback((event: { target: YouTubePlayer }) => {
    setPlayer(event.target);
    mirrorVideoAdd(event.target, "yt");
    mirrorVideoLoad("", "ad");
  }, []);

  const handleOnStateChange = useCallback(
    async ({ data }: { data: number }) => {
      const currentTime = await player?.getCurrentTime();

      switch (data) {
        case YouTube.PlayerState.ENDED:
          break;
        case YouTube.PlayerState.PAUSED:
          if (uniqueVideoId === currentYoutubeVideoId) {
            currentTime &&
              handleYouTubeVideoGA4Tracking({
                action_type: "pause",
                article_id: trackingData.ga4ArticleEntityId,
                player_name: "youtube_pfp",
                position: trackingData.ga4Position ?? "article_body",
                time: Math.round(currentTime),
                video_id: trackingData.videoEntityId,
                youtube_id: trackingData.youTubeVideoId,
              });

            setOnlyOnePlayingPlayer(state => ({
              ...state,
              currentYoutubeVideoId: undefined,
              youtubePlayer: undefined,
            }));
          }
          break;
        case YouTube.PlayerState.PLAYING:
          if (uniqueVideoId !== currentYoutubeVideoId) {
            currentTime &&
              handleYouTubeVideoGA4Tracking({
                action_type: "play",
                article_id: trackingData.ga4ArticleEntityId,
                player_name: "youtube_pfp",
                position: trackingData.ga4Position ?? "article_body",
                time: Math.round(currentTime),
                video_id: trackingData.videoEntityId,
                youtube_id: trackingData.youTubeVideoId,
              });

            sendMirrorCustomEvent({
              action: "Play",
              event_name: "video_plays",
              video_vertical: onePlusVideoVertical,
            });
            setOnlyOnePlayingPlayer(state => ({
              ...state,
              currentYoutubeVideoId: uniqueVideoId,
              youtubePlayer: player,
            }));
          }

          break;
      }
    },
    [
      player,
      uniqueVideoId,
      currentYoutubeVideoId,
      onePlusVideoVertical,
      trackingData.ga4ArticleEntityId,
      trackingData.ga4Position,
      trackingData.youTubeVideoId,
      trackingData.videoEntityId,
      setOnlyOnePlayingPlayer,
    ],
  );

  return {
    handleOnEnd,
    handleOnReady,
    handleOnStateChange,
    player,
  };
};
