import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { config } from "shared/data";
import { accountAtom } from "shared/lib/account";

import { useScmpWidgetConfig } from "scmp-app/lib/app/hooks";
import { useMyNews, useParsedCookie } from "scmp-app/lib/hooks";
import { ScmpSubscriptionsKey } from "scmp-app/lib/rosetta/hooks/consts";

import type { AdUnit } from "./types";

enum REGISTER_TYPE {
  APPLE = "apple",
  EMAIL = "email",
  FACEBOOK = "facebook",
  GOOGLE = "google",
  GOOGLE_GSUITE = "google:gsuite",
  GOOGLE_YOLO = "google:yolo",
  PIANO_EMAIL = "piano:email",
  PIANO_SWG = "piano:swg",
}
enum USER_LEVEL {
  SCMP_EXPERT = "SCMP Expert",
  SCMP_STAFF = "SCMP Staff",
}
export const useUserRoleTarget = () => {
  const { isLoggedIn, user } = useAtomValue(accountAtom);
  const { value: scmpSubscriberCookie } = useParsedCookie("scmp_subscriber", raw => raw, {
    initializeWithValue: true,
  });
  const { value: scmpSubscriptions = [] } = useFallbackStorageValue<string[]>(ScmpSubscriptionsKey);
  const isScmpPlusSubscriber = scmpSubscriptions?.includes("scmp-plus");

  return useMemo(() => {
    if (!isLoggedIn) return { sub: "user", subType: "1" };
    if (user?.lvl?.includes(USER_LEVEL.SCMP_STAFF)) return { sub: "staff", subType: "13" };
    if (user?.lvl?.includes(USER_LEVEL.SCMP_EXPERT)) return { sub: "paid", subType: "12" };

    // Use cookie here is because don't want to delay the ad rendering
    const isScmpSubscriber = new Set(["active", "true"]).has(scmpSubscriberCookie ?? "");
    // prettier-ignore
    const sub = isScmpPlusSubscriber ? "scmp-plus" : (isScmpSubscriber ? "paid" : "user");
    const hasSCMPTargetedSubscription = isScmpSubscriber || isScmpPlusSubscriber;
    let subType = hasSCMPTargetedSubscription ? "11" : "6";

    const map: Record<string, string> = {
      [REGISTER_TYPE.EMAIL]: hasSCMPTargetedSubscription ? "9" : "4",
      [REGISTER_TYPE.FACEBOOK]: hasSCMPTargetedSubscription ? "7" : "2",
      [REGISTER_TYPE.GOOGLE]: hasSCMPTargetedSubscription ? "8" : "3",
      [REGISTER_TYPE.GOOGLE_YOLO]: hasSCMPTargetedSubscription ? "10" : "5",
    };
    if (user?.source && map[user.source]) subType = map[user.source];
    return { sub, subType };
  }, [isLoggedIn, isScmpPlusSubscriber, scmpSubscriberCookie, user?.lvl, user?.source]);
};

export const useAdProfile = () => {
  const { scmpWidgetConfigState } = useScmpWidgetConfig();
  const { hasEnoughFollowedItems, isVisitedMyNewsPage } = useMyNews();
  const { isLoggedIn } = useAtomValue(accountAtom);
  const [adProfile, setAdProfile] = useState<string[]>([]);

  useEffect(() => {
    if (
      !scmpWidgetConfigState.result?.my_news.is_enabled_in_ad_profile ||
      !isVisitedMyNewsPage ||
      hasEnoughFollowedItems ||
      !isLoggedIn
    )
      return;
    // Need to setup the ad profile when
    // 1. Landing popup has been shown
    // 2. Don't have enough follow items to setup mynews page
    // 3. Drupal config is on
    // 4. User is logged in
    setAdProfile(["mynews"]);
  }, [
    hasEnoughFollowedItems,
    isLoggedIn,
    isVisitedMyNewsPage,
    scmpWidgetConfigState.result?.my_news.is_enabled_in_ad_profile,
  ]);

  return {
    adProfile,
  };
};

export const useAdUnitPath = (adUnit: AdUnit, zone?: null | string) => {
  const { adUnitPrefix, networkId } = config.advertisement.googlePublisherTag;
  return `/${networkId}/${adUnitPrefix}/${zone ?? "default"}/${adUnit}`;
};
