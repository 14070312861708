import "core-js/modules/esnext.json.parse.js";
import { useFallbackStorageValue } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { getGoogleAnalyticsClientId } from "shared/components/tracking/google-analytics/apis";
import { accountAtom } from "shared/lib/account";
import { syncMirrorGAUserIds } from ".";
export function useMirrorTrackPageLoad() {
  const {
    user
  } = useAtomValue(accountAtom);
  useEffect(() => {
    const run = async () => {
      const gaClientId = await getGoogleAnalyticsClientId();
      if (!gaClientId) return;
      syncMirrorGAUserIds(gaClientId);
    };
    void run();
  }, [user]);
}
export function useLighthouseMapValue() {
  return useFallbackStorageValue("lh2_map", {
    parse(data) {
      if (!data) return {};
      try {
        return JSON.parse(data);
      } catch {
        return {};
      }
    },
    stringify(data) {
      return JSON.stringify(data);
    }
  });
}